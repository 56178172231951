<template>
    <div >



    </div>
</template>

<style>
    *{
        font-family: math;
    }
    .van-circle__layer {
        stroke: #e2e6eb;
    }
</style>
<script>

export default {
  data () {
    return {

    }
  },
  components: {
  },
  watch: {

  },
  computed: {

  },
  mounted() {
    document.title = '影子VPN';  // 修改页面标题
    // 判断是否是移动端
    const isMobile = this.isMobileDevice();
    const isMobileEx = this.isMobileBrowerSize();
    // 如果是移动端，则跳转到 "/phome" 页面
    if (isMobile||isMobileEx) {
      this.$router.replace('/mhome');
    }else{
      this.$router.replace('/phome');
    }

  },
  methods: {
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    }

  },
  beforeDestroy() {

  },

}
</script>

<style lang="less" scoped>

</style>
